<template>
  <v-data-table :headers="headers" :items="records" disable-sort>
    <template #[`item.name`]="{ item: { name } }">
      <div class="text-no-wrap">
        {{ name }}
      </div>
    </template>
    <template #[`item.compliance`]="{ item: { id, subscriptions } }">
      <v-checkbox
        v-model="subscriptions.compliance"
        hide-details
        class="pa-0 ma-0"
        @change="onSubscriptionChange(id, 'compliance')"
      />
    </template>
    <template #[`item.secretary`]="{ item: { id, subscriptions } }">
      <v-checkbox
        v-model="subscriptions.secretary"
        hide-details
        class="pa-0 ma-0"
        @change="onSubscriptionChange(id, 'secretary')"
      />
    </template>
    <template #[`item.officerAppointments`]="{ item: { id, subscriptions } }">
      <v-checkbox
        v-model="subscriptions.officerAppointments"
        hide-details
        class="pa-0 ma-0"
        @change="onSubscriptionChange(id, 'officerAppointments')"
      />
    </template>
  </v-data-table>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'SubscriptionsTable',

  props: {
    records: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    headers: [
      { text: 'NAME', value: 'name' },
      { text: 'COMPLIANCE', value: 'compliance' },
      { text: 'COMPANY SECRETARY', value: 'secretary' },
      { text: 'OFFICER APPOINTMENTS', value: 'officerAppointments' },
    ],
  }),

  methods: {
    ...mapActions('companies', ['updateCompanySubscription']),

    async onSubscriptionChange(id, type) {
      const payload = {
        id,
        type,
      }

      const { success, message } = await this.updateCompanySubscription(payload)

      const notificationType = success ? 'success' : 'error'
      const position = 'bottom'
      this.AcNotify({
        type: notificationType,
        message,
        position,
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
