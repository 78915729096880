import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.records,"disable-sort":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var name = ref.item.name;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(name)+" ")])]}},{key:"item.compliance",fn:function(ref){
var ref_item = ref.item;
var id = ref_item.id;
var subscriptions = ref_item.subscriptions;
return [_c(VCheckbox,{staticClass:"pa-0 ma-0",attrs:{"hide-details":""},on:{"change":function($event){return _vm.onSubscriptionChange(id, 'compliance')}},model:{value:(subscriptions.compliance),callback:function ($$v) {_vm.$set(subscriptions, "compliance", $$v)},expression:"subscriptions.compliance"}})]}},{key:"item.secretary",fn:function(ref){
var ref_item = ref.item;
var id = ref_item.id;
var subscriptions = ref_item.subscriptions;
return [_c(VCheckbox,{staticClass:"pa-0 ma-0",attrs:{"hide-details":""},on:{"change":function($event){return _vm.onSubscriptionChange(id, 'secretary')}},model:{value:(subscriptions.secretary),callback:function ($$v) {_vm.$set(subscriptions, "secretary", $$v)},expression:"subscriptions.secretary"}})]}},{key:"item.officerAppointments",fn:function(ref){
var ref_item = ref.item;
var id = ref_item.id;
var subscriptions = ref_item.subscriptions;
return [_c(VCheckbox,{staticClass:"pa-0 ma-0",attrs:{"hide-details":""},on:{"change":function($event){return _vm.onSubscriptionChange(id, 'officerAppointments')}},model:{value:(subscriptions.officerAppointments),callback:function ($$v) {_vm.$set(subscriptions, "officerAppointments", $$v)},expression:"subscriptions.officerAppointments"}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }